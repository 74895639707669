<template>
  <!--<p>question + {{questionID}}</p>-->
  <!-- 题目目录 -->
  <!--答题内容区域-->
  <div>
    <!-- 题目里面有哪个就渲染哪个要改一下 -->
    <choice v-if="examType == 'choice'" :category="my_category"></choice>
    <multipleChoice v-if="examType == 'multipleChoice'" :category="my_category"></multipleChoice>
    <fillBlank v-if="examType == 'fillBlank'" :category="my_category"></fillBlank>
    <judge v-if="examType == 'judge'" :category="my_category"></judge>
    <programming v-if="examType == 'programming'" :category="my_category"></programming>



    <!-- 多选题 -->
    <!-- <multipleChoice v-if="examType == 2&&my_category.indexOf('Scratch')!=-1" :category="my_category"></multipleChoice> -->
  </div>
  <!-- 答题进度条 -->

  <!-- 功能按钮 -->
</template>

<script>
import { countDown, getUserMedia } from "../utils/utils.js";
import choice from "../components/question/choice";
import fillBlank from "../components/question/fillBlank";
import judge from "../components/question/judge";
import multipleChoice from "../components/question/multipleChoice";

import programming from "../components/question/programming";

export default {
  name: "question",
  data() {
    return {
      examType: -1,
      my_category: "",
    };
  },
  components: {
    choice,
    fillBlank,
    judge,
    multipleChoice,
    programming,
  },
  methods: {},
  mounted() {
    this.my_category = this.$localStorage.get("login_status_category"); //获取参赛学院类别
    // this.examType = 2;
    let params = this.$route.params;
    //调试页面在这里，0=选择题 1=填空题 2=编程题
    // params.examType = 2;
    //检测到有值传过来那么就加载组件，没有的话就返回到notcie界面，来答题界面必须经过notice
    console.log(params.examType);
    // 后面要变回来
    if (params.examType === 0 || params.examType) {
      this.examType = params.examType;
      console.log(params.examType);
    } else {
      this.$router.push({ name: "Notice", params: { id: "3" } }); //一会开开
    }
  },
  filters: {},
};
</script>


<style>
#app {
  background: #eee;
  height: 100vh;
}

[v-cloak] {
  /*解决第一次进入页面时出现代码等待渲染的情况*/
  display: none !important;
}

html,
body {
  font-size: 62.5%;
  height: 100%;
  margin: 0 auto;
}
.el-progress-bar__innerText {
  height: 20px !important;
}

/* ____________________________________ */
.flex-containor {
  width: 1200px;
  margin: 150px auto 80px auto;
  display: flex;
}
.question-container {
  width: 75%;
  margin-right: 30px;
}

.question-area {
  padding: 0 1rem;
  flex: 1;
}

.right-container {
  text-align: center;
  width: 22%;
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  /* text-align: right; */
}

video {
  margin: 20px;
}
.right-container_title {
  font-size: 15px;
  margin: 10px;
}
.right-container_title::before,
.right-container_title::after {
  color: #aaa;
  content: "—————";
  margin: 20px 10px;
}

.el-progress {
  width: 70%;
}
.el-tag {
  width: 3.5rem;
  margin: 0.5rem;
  padding: 0;
}

.el-card {
  min-height: 39rem;
  text-align: left;
}

.ques-head {
  font-size: 1.6rem;
}

.input-text {
  height: 3rem;
  line-height: 3rem;
  font-size: 1.4rem;
  margin: 1rem 0;
}

.input-text input {
  margin-right: 1rem;
}

.el-radio {
  width: 100%;
}
.el-radio__label {
  font-size: 15px;
  padding-left: 10px;
}

/*自定义单选框样式结束*/

.progress-area {
  display: flex;
  align-items: center;
  font-family: "黑体";
  font-weight: bold;
  justify-content: space-between;
  /* padding: 10px; */
  border-radius: 5px;
  background: white;
  margin-bottom: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.progress-area .cout_time {
  margin: 15px;
}
.el-progress-bar__outer {
  margin: 15px;
}
.progress-area div {
  font-size: 18px;
}
.buttons-group {
  text-align: right;
  margin-top: 15px;
}

.buttons-group button {
  width: 9.5rem;
}
</style>