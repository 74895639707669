<template>
  <!-- 题目目录 -->
  <!--答题内容区域-->
  <div>
    <topBanner></topBanner>
    <div class="flex-containor">
      <div class="question-container">
        <div class="progress-area">
          <el-progress
            :text-inside="true"
            :stroke-width="20"
            :percentage="progressPercent"
            :status="progressStyle"
          ></el-progress>
          <countDown1 @submit="submitAuto"></countDown1>
          <!-- <div class="cout_time">倒计时：{{ time }}</div> -->
          <div class="cout_time"></div>
        </div>
        <el-card
          class="box-card"
          v-for="(subject, questionIndex) in subjects"
          :key="subject.id"
          v-show="questionIndex === questionID"
        >
          <!-- 题目内容 -->
          <div slot="header">
            <span class="ques-head"
              >题目{{ questionIndex + 1 }}：{{ subject.question }}</span
            >
          </div>
          <!-- 题目选项 -->
          <div class="input-box"></div>
          <h3>示意图</h3>
          <img
            v-if="subject.questionImg"
            :src="subject.img"
            :class="subject.imgSmall == true ? 'imgSmall' : ''"
            alt
          />
          {{ subject.quesNum }}
          <div
            v-for="inner in Number(subject.quesNum)"
            :key="inner"
            class="uploadBox"
          >
            <div>
              第{{ inner }}小问
              <el-upload
                ref="upload"
                :http-request="
                  (response) => {
                    return submitFile(response, inner);
                  }
                "
                :limit="1"
                :auto-upload="true"
                class="upload"
                drag
                action="#"
              >
                <span class="hitS red">(必需项)</span>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  <div class="hit">请上传程序源码</div>
                  <div>
                    （将文件拖到此处，或
                    <em>点击上传）</em>
                  </div>
                </div>
                <div class="el-upload__tip" slot="tip">请上传.sb格式的文件</div>
              </el-upload>
            </div>
          </div>

          <!-- <el-radio-group v-model="checkedID" @change="getChoice(questionIndex,checkedID)"></el-radio-group> -->
        </el-card>
      </div>

      <div class="right-container" v-if="category.indexOf('scratch') != -1">
        <div class="buttons-group">
          <el-button type="primary" @click="prevSubject">上一题</el-button>
          <el-button type="primary" @click="nextSubject">下一题</el-button>
          <el-button type="primary" @click="submitAll">全部提交</el-button>
        </div>
      </div>

      <!-- python c++ -->
      <div class="right-container" v-if="category.indexOf('python') != -1">
        <span style="position: absolute; top: 10px; left: 10px"
          >导入库需要使用import turtle as t</span
        >

        <code-editor
          :options="options"
          :value="content"
          @contentChange="contentChange"
          ref="editor"
          style="height: 680px; width: 40%; display: inline-block"
        ></code-editor>
        <div class="console">
          <!-- 绘图框 -->
          <div id="mycanvas" height="600" width="800"></div>
          <!-- 输出框 -->

          <pre id="output"></pre>

          <div class="button-tool">
            <!-- <button id="runbutton" class="tool-item run"><span class="ux-scratch-icon-ide-run"></span>运行</button> -->
            <el-button
              type="warning"
              style="height: 19px"
              icon="el-icon-video-play"
              @click="runit"
              >运行</el-button
            >
          </div>
        </div>

        <div class="buttons-group">
          <el-button type="primary" @click="prevSubject">上一题</el-button>
          <el-button type="primary" @click="nextSubject">下一题</el-button>
          <el-button type="primary" @click="submitAll">提交</el-button>
        </div>
      </div>

      <div class="right-container" v-if="category.indexOf('c++') != -1">
        <code-editor
          :options="options"
          :value="content"
          @contentChange="contentChange"
          ref="editor"
          style="height: 620px; width: 70%; display: inline-block"
        ></code-editor>

        <div class="buttons-group">
          <el-button type="primary" @click="prevSubject">上一题</el-button>
          <el-button type="primary" @click="nextSubject">下一题</el-button>
          <el-button type="primary" @click="submitAll">提交</el-button>
        </div>
      </div>
    </div>
  </div>

  <!-- 答题进度条 -->

  <!-- 功能按钮 -->
</template>

<script>
// import CodeEditor from "../../components/IDE";
import topBanner from "../../components/topBanner";

import CodeEditor from "../python_ide/CodeEditor"; //引入插件
import {
  returnQuestion,
  countDown,
  getUserMedia,
  submitAll,
} from "../../utils/utils.js";
import { Loading } from "element-ui";

import countDown1 from "../countdown";
import ScrachData from "../../storage/Scratch";
import pythonData from "../../storage/Python";
import CData from "../../storage/C";

export default {
  name: "programming",
  data() {
    return {
      questionID: 0, //问题的索引值，默认显示第一题
      answer: "", //选项的索引值，未选择时为-1
      progressPercent: 0,
      progressStyle: "warning",
      subjects: [],
      ///////////////////
      state: "",
      time: "",
      //编辑器选项
      options: {
        language: "python",
        theme: "darcula",
        readOnly: false,
      },
      content: "",
    };
  },
  props: {
    category: String,
  },
  components: {
    CodeEditor,
    topBanner,
    countDown1,
  },
  methods: {
    //点击下一题触发
    nextSubject() {
      //判断是否是最后一题
      if (this.questionID === this.subjects.length - 1) {
        this.questionID = this.subjects.length - 1;
        this.$message({
          message: "已经是最后一题，确认无误可以点击提交",
          center: true,
          type: "warning",
          showClose: true,
          duration: 1000,
        });
      } else {
        this.questionID += 1;
      }

      //将本题得答案赋值
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      this.showChoice(this.questionID);

      if (this.category.indexOf("Scratch") != -1)
        this.$refs.upload.clearFiles();
      else this.runit("print('success')");
    },
    //点击上一题触发
    prevSubject() {
      //判断是否是第一题
      if (this.questionID === 0) {
        this.questionID = 0;
        this.$message({
          message: "已经是第一题",
          center: true,
          type: "warning",
          showClose: true,
          duration: 1000,
        });
      } else {
        this.questionID -= 1;
      }

      document.body.scrollTop = document.documentElement.scrollTop = 0;

      this.showChoice(this.questionID);

      if (this.category.indexOf("Scratch") != -1)
        this.$refs.upload.clearFiles();
      else this.runit("print('success')");
    },
    //每次点击选项都触发，获取题号和选项，并放到store中保存
    contentChange(answer) {
      this.myCode = answer;

      //////////////////////////////////------------
      console.log(answer);

      var programming = {
        quesIndex: this.questionID,
        answer: answer,
      };

      this.$store.commit("updateRecord", {
        choice: programming,
        type: "programming",
      });

      this.changeProgress();
    },
    //这里上传文件，需要修改
    submitFile(res, index) {
      //scracth涉及
      console.log("here", res, index);
      let loadingInstance = Loading.service();
      let username = this.$localStorage.get("login_status_username");
      let examID = this.$localStorage.get("examID");
      //上传文件到文件夹下
      this.$serverLess
        .uploadFile({
          // 云存储的路径  szs需要改/
          cloudPath: `online_scratch/${examID}/${username}/${this.questionID}.sb3`,
          // 需要上传的文件，File 类型
          filePath: res.file,
          //有问题this.fileList[i][0].raw
        })
        .then((res) => {
          // 返回文件 ID
          console.log(res.fileID);
          var programming = {
            quesIndex: this.questionID,
            answer: res.fileID,
          };
          // console.log(answer);

          this.$store.commit("updateRecord", {
            choice: programming,
            type: "programming",
          });
          this.changeProgress();
          loadingInstance.close();
          this.$message({
            message: "上传成功",
            center: true,
            type: "success",
            showClose: true,
            duration: 1500,
          });
        })
        .catch((err) => {
          loadingInstance.close();
          this.$message({
            message: "上传失败" + err,
            center: true,
            type: "error",
            showClose: true,
            duration: 1500,
          });
        });
    },
    //每次显示题目的同时获取该题目已选择哪一项并显示
    showChoice(id) {
      var programming = this.$store.state.programming;
      let flag = false;
      console.log(programming);
      if (this.category.indexOf("Scratch") == -1) {
        //scratch不需要恢复内容
        programming.forEach((item) => {
          if (item != null) {
            if (item.quesIndex === id) {
              flag = true;
              this.$refs.editor.setValue(item.answer); //将本题得答案赋值
            }
          }
        });
        if (flag === false) {
          this.$refs.editor.setValue("");
        }
      }
    },
    //更新进度条长度
    changeProgress() {
      var storeLen = (() => {
        let len = 0;
        this.$store.state.programming.forEach((item) => {
          if (item != null) {
            len++;
          }
        });
        return len;
      })();
      var subjectsLen = this.subjects.length;
      this.progressPercent = Math.floor((storeLen / subjectsLen) * 100);
      if (this.progressPercent === 100) {
        this.progressStyle = "success";
      }
    },
    goToQues(index) {
      this.questionID = index - 1;
      this.showChoice(this.questionID);
    },
    //提交并评分
    async submitAll() {
      this.$confirm("确定提交题目？一经提交题目将无法修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var storeLen = this.$store.state.programming.length;
          var subjectsLen = this.subjects.length;
          storeLen=999//不限制交卷
          if (storeLen < subjectsLen) {
            this.$message({
              message: "提交失败，请完成所有题目再提交",
              center: true,
              type: "error",
              showClose: true,
              duration: 2000,
            });
          } else {
            let subject = await submitAll("programming"); //获取题目
            console.log(subject);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "提交失败" + err,
          });
        });
    },
    async submitAuto() {
      await submitAll("programming"); //获取题目
    },
    dataInit() {
      this.$store.state.scorePerQues = Math.floor(100 / this.subjects.length);
      //从缓存中获取题目，并显示
      this.$store.state.programming = this.$localStorage.get("programming");
      let programming = this.$store.state.programming;
      //如果是空的那么就不进行下面的渲染
      if (programming === null) return;
      this.showChoice(0);
      this.changeProgress();
    },
    outf(text) {
      var mypre = document.getElementById("output");
      mypre.innerHTML = mypre.innerHTML + text;
    },
    builtinRead(x) {
      if (
        Sk.builtinFiles === undefined ||
        Sk.builtinFiles["files"][x] === undefined
      )
        throw "File not found: '" + x + "'";
      return Sk.builtinFiles["files"][x];
    },
    runit(code) {
      this.$message("程序运行中");
      setTimeout(() => {
        var prog =
          code == "print('success')"
            ? "import turtle\r\nturtle.clear\r\nprint('success')"
            : this.myCode;
        // var prog = document.getElementById("yourcode").value;
        var mypre = document.getElementById("output");
        mypre.innerHTML = "";
        Sk.pre = "output";
        Sk.configure({
          output: this.outf,
          read: this.builtinRead,
          __future__: Sk.python3,
        });

        (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = "mycanvas"; //调整画布大小在skulpt-stdlib搜 width:600这里就可以修改了,width,height可以修改

        var myPromise = Sk.misceval.asyncToPromise(function () {
          return Sk.importMainWithBody("<stdin>", false, prog, true);
        });

        myPromise.then(
          (mod) => {
            Sk.configure({
              output: this.outf("success"),
              read: this.builtinRead,
              __future__: Sk.python3,
            });
            console.log("success");
          },
          (err) => {
            Sk.configure({
              output: this.outf("\n" + err.toString()),
              read: this.builtinRead,
              __future__: Sk.python3,
            });
            console.log(err.toString());
          }
        );
      }, 300);
    },
  },
  async mounted() {
    let subject = await returnQuestion(this.category); //获取题目
    this.subjects = subject.programming;

    let daojishi = setInterval(() => {
      this.time = countDown("programming");
      if (this.time === "00:00:00") {
        this.time = "时间到";
        clearInterval(daojishi);
      }
    }, 1000);

    let video = document.getElementById("video");
    // let canvas = document.getElementById("canvas");
    // let context = canvas.getContext("2d");
    //成功回调
    function success(stream) {
      video.srcObject = stream;
      video.play();
    }
    //失败回调
    function error(error) {
      console.log("访问用户媒体失败");
    }
    //开启摄像头
    if (
      navigator.mediaDevices.getUserMedia ||
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    ) {
      getUserMedia({ video: { width: 400, height: 300 } }, success, error);
    } else {
      alert("不支持");
    }
    //实现拍照的功能
    // document.getElementById("snap").addEventListener("click", function() {
    //   context.drawImage(video, 0, 0, 500, 500);
    // });
    setTimeout(() => {
      this.dataInit();
    }, 0);
  },

  filters: {
    //将选项的索引值转化为ABCD...的选项
    numToChoice(num) {
      return String.fromCharCode(num + 65);
    },
  },
};
</script>



<style scoped>
/* ____________________________________ */
.flex-containor {
  width: 93%;
  margin: 50px auto 80px auto;
  display: flex;
  flex-wrap: wrap;
}
.question-container {
  width: 100%;
  margin: 20px;
}

.question-area {
  padding: 0 1rem;
  flex: 1;
}

.right-container {
  position: relative;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  background: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.right-container_title {
  font-size: 15px;
  margin: 10px;
}
.right-container_title::before,
.right-container_title::after {
  color: #aaa;
  content: "—————";
  margin: 20px 10px;
}

.input-box {
  font-size: 16px;
}

.el-tag {
  width: 3.5rem;
  margin: 0.5rem;
}
/* 决定高度 */
.el-card {
  min-height: 60vh;
  text-align: left;
}

.ques-head {
  font-size: 1.6rem;
}

.input-text {
  height: 3rem;
  line-height: 3rem;
  font-size: 1.4rem;
  margin: 1rem 0;
}

.input-text input {
  margin-right: 1rem;
}
/* 左侧题目说明 */
.box-card .imgSmall {
  width: 50%;
}
/* 编辑器 */
.right-container {
  text-align: left;
  width: 100%;
  margin: 20px;
}

.console {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: inline-block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 55%;
  height: 670px;
  background-color: #ecf8ff;
  border-left: 1px solid #87746d;
}
#mycanvas {
  width: 600px;
  height: 60%;
  min-height: calc(639px * 0.6);
  border-bottom: 1px solid #87746d;
}
#output {
  height: 20%;
  min-height: calc(639px * 0.3);
  background: #41444e;
  color: white;
  text-align: left;
  z-index: 99;
}

.button-tool {
  /* 避免被canvas盖住 */
  position: relative;
  z-index: 99999;
  height: 55px;
  padding: 18px 28px;
  background: #ecf8ff;
  text-align: left;
  border-top: 1px solid #87746d;
  overflow: hidden;
}

.buttons-group {
  position: relative;
  z-index: 2020;
}

.mtk1 {
  color: black;
}

.uploadBox {
  display: flex;
}
</style>