<template>
  <!--<p>question + {{questionID}}</p>-->
  <!-- 题目目录 -->
  <!--答题内容区域-->
  <!-- judge是两个选项的选择题 -->
  <div>
    <topBanner></topBanner>
    <div class="flex-containor">
      <div class="question-container">
        <div class="progress-area">
          <el-progress
            :text-inside="true"
            :stroke-width="20"
            :percentage="progressPercent"
            :status="progressStyle"
          ></el-progress>
          <!-- <div class="cout_time">倒计时：{{ time }}</div> -->
          <div class="cout_time"></div>
        </div>
        <el-card
          class="box-card"
          v-for="(subject, questionIndex) in subjects"
          :key="subject.id"
          v-show="questionIndex === questionID"
        >
          <!-- 题目内容 -->
          <div slot="header">
            <span class="ques-head"
              >题目{{ questionIndex + 1 }}：{{ subject.question }}</span
            >
          </div>

          <!-- 题目选项 -->
          <div class="question_containor">
            <img
              class="question_img"
              v-if="subject.img"
              :src="subject.img"
              alt
            />
            <el-radio-group
              v-model="checkedID"
              @change="getjudge(questionIndex, checkedID)"
            >
              <!-- JudgeAnswer就两种：对或者错 -->
              <el-radio
                v-for="(answer, judgeIndex) in JudgeAnswer"
                :key="judgeIndex"
                class="input-text"
                :label="judgeIndex"
                >{{ answer }}</el-radio
              >
            </el-radio-group>
          </div>
        </el-card>

        <div class="buttons-group">
          <el-button type="primary" @click="prevSubject">上一题</el-button>
          <el-button type="primary" @click="nextSubject">下一题</el-button>
          <el-button type="primary" @click="submitAll">提交</el-button>
        </div>
      </div>

      <div class="right-container">
        <video id="video" width="200" height="150"></video>
        <div class="right-container_title">题号</div>
        <div class="tag-group" ref="tags">
          <el-tag
            type="primary"
            effect="plain"
            v-for="index in subjects.length"
            :key="index"
            style="cursor: pointer"
            @click="goToQues(index)"
            >{{ index }}</el-tag
          >
          <!--  -->
        </div>
      </div>
    </div>
  </div>

  <!-- 答题进度条 -->

  <!-- 功能按钮 -->
</template>

<script>
import topBanner from "../../components/topBanner";

import {
  returnQuestion,
  countDown,
  getUserMedia,
  submitAll,
} from "../../utils/utils.js";
import { Loading } from "element-ui";



export default {
  name: "judge",
  data() {
    return {
      questionID: 0, //问题的索引值，默认显示第一题
      checkedID: -1, //选项的索引值，未选择时为-1
      progressPercent: 0,
      progressStyle: "warning",
      subjects: [],
      ///////////////////
      state: "",
      time: "",
      JudgeAnswer: ["对", "错"],
    };
  },
  props: {
    category: String,
  },
  components: { topBanner },
  methods: {
    //点击下一题触发
    nextSubject() {
      //判断是否是最后一题
      if (this.questionID === this.subjects.length - 1) {
        this.questionID = this.subjects.length - 1;
        this.$message({
          message: "已经是最后一题，确认无误可以点击提交",
          center: true,
          type: "warning",
          showClose: true,
          duration: 1000,
        });
      } else {
        this.questionID += 1;
      }
      this.checkedID = -1;
      this.showChoice(this.questionID);
    },
    //点击上一题触发
    prevSubject() {
      //判断是否是第一题
      if (this.questionID === 0) {
        this.questionID = 0;
        this.$message({
          message: "已经是第一题",
          center: true,
          type: "warning",
          showClose: true,
          duration: 1000,
        });
      } else {
        this.questionID -= 1;
      }
      this.checkedID = -1;
      this.showChoice(this.questionID);
    },
    //每次点击选项都触发，获取题号和选项，并放到store中保存
    getjudge(quesIndex, judgeIndex) {
      console.log(quesIndex, judgeIndex);

      let judge = {
        quesIndex: quesIndex,
        judgeIndex: judgeIndex,
        // correctness: correctness
      };
      this.checkedID = judgeIndex;
      this.$refs.tags.children[quesIndex].className =
        "el-tag el-tag--primary el-tag--dark";
      this.$store.commit("updateRecord", { choice: judge, type: "judge" });
      this.changeProgress();
    },
    //每次显示题目的同时获取该题目已选择哪一项并显示
    showChoice(id) {
      let judge = this.$store.state.judge;
      let judgeIndex = -1;
      judge.forEach((item) => {
        if (item != null) {
          if (item.quesIndex === id) {
            judgeIndex = item.judgeIndex;
          }
        }
      });
      this.checkedID = judgeIndex;
    },
    //更新进度条长度
    changeProgress() {
      var storeLen = (() => {
        let len = 0;
        this.$store.state.judge.forEach((item) => {
          if (item != null) {
            len++;
          }
        });
        return len;
      })();
      var subjectsLen = this.subjects.length;
      this.progressPercent = Math.floor((storeLen / subjectsLen) * 100);
      if (this.progressPercent === 100) {
        this.progressStyle = "success";
      }
    },
    goToQues(index) {
      this.questionID = index - 1;
      console.log(this.questionID);
      this.showChoice(this.questionID);
    },
    //提交并评分
    async submitAll() {
      this.$confirm("确定提交题目？一经提交题目将无法修改", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // this.$localStorage.set("steam_exam_status", [""]); //测试用一会删掉
          // this.$router.push({ name: "Notice", params: { id: "3" } }); //跳转页面测试用一会删除

          var storeLen = this.$store.state.judge.length;
          var subjectsLen = this.subjects.length;
          if (storeLen < subjectsLen) {
            this.$message({
              message: "提交失败，请完成所有题目再提交",
              center: true,
              type: "error",
              showClose: true,
              duration: 2000,
            });
          } else {
            let subject = await submitAll("judge"); //获取题目
            console.log(subject);
          }
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "提交失败" + err,
          });
        });
    },
    dataInit() {
      this.$store.state.scorePerQues = Math.floor(100 / this.subjects.length);
      //从缓存中获取题目，并显示
      this.$store.state.judge =
        this.$localStorage.get("judge") === null
          ? []
          : this.$localStorage.get("judge");
      let judge = this.$store.state.judge;
      //如果是空的那么就不进行下面的渲染
      if (judge === null) return;
      this.showChoice(0);
      this.changeProgress();

      //遍历渲染tag
      judge.forEach((item, index) => {
        if (item != null) {
          // console.log(this.$refs.tags.children, index);
          this.$refs.tags.children[index].className =
            "el-tag el-tag--primary el-tag--dark";
        }
      });
    },
  },
  async mounted() {
    let subject = await returnQuestion(this.category); //获取题目
    this.subjects = subject.judge;

    let daojishi = setInterval(() => {
      this.time = countDown("judge");
      if (this.time === "00:00:00") {
        // this.time = "时间到";
        this.time = "公测中暂不计时";

        clearInterval(daojishi);
      }
    }, 1000);

    let video = document.getElementById("video");
    // let canvas = document.getElementById("canvas");
    // let context = canvas.getContext("2d");
    //成功回调
    function success(stream) {
      video.srcObject = stream;
      video.play();
    }
    //失败回调
    function error(error) {
      console.log("访问用户媒体失败");
    }
    //开启摄像头
    if (
      navigator.mediaDevices.getUserMedia ||
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    ) {
      getUserMedia({ video: { width: 400, height: 300 } }, success, error);
    } else {
      alert("不支持");
    }
    //实现拍照的功能
    // document.getElementById("snap").addEventListener("click", function() {
    //   context.drawImage(video, 0, 0, 500, 500);
    // });
    setTimeout(() => {
      //没有获取到dom数据将函数压到宏队列
      this.dataInit();
    }, 0);
  },

  filters: {
    //将选项的索引值转化为ABCD...的选项
    numTojudge(num) {
      return String.fromCharCode(num + 65);
    },
  },
};
</script>

<style  scoped>
.question_containor {
  display: flex;
  flex-wrap: nowrap;
}
.question_img {
  margin: 0 50px 0 0;
  width: 300px;
  height: auto;
}
</style>


